<template>
  <div class="index">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <!-- swiper 轮播 -->
    <div class="swiper">
      <!-- <img src="/imgs/banner.jpg" alt="" /> -->
      <div class="swiper-box">
        <swiper :options="swiperOption">
          <!-- 循环想 -->
          <swiper-slide v-for="(item, index) in this.swiperList" :key="index">
            <a href="javascript:;"><img :src="item.img" /></a>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
    </div>

    <!-- 关于我们区块 -->
    <div class="us-con">
      <div class="w">
        <!-- 左侧 -->
        <div class="us-l">
          <h3>ABOUT US</h3>
          <h2>杭州思阅科技有限公司</h2>
          <p>
            杭州思阅科技有限公司成立于2022年03月29日，注册地位于浙江省杭州市拱墅区银泰城6幢1702室，经营范围包括：一般项目：组织文化艺术交流活动；技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；信息技术咨询服务；计算机系统服务；信息系统集成服务；广告制作；广告设计、代理；版权代理；广告发布；项目策划与公关服务；摄像及视频制作服务(除依法须经批准的项目外，凭营业执照依法自主开展经营活动)等...
          </p>
          <div class="us-btn">查看更多</div>
        </div>
        <!-- 右侧 -->
        <div class="us-r">
          <img src="/imgs/us.jpg" alt="" />
        </div>
      </div>
    </div>

    <!-- 定宽布局 -->
    <div class="w">
      <!-- 动漫容器 -->
      <div class="con">
        <!-- 左侧 -->
        <div class="l">
          <img src="/imgs/i1.jpg" alt="" />
          <!-- 循环项容器 -->
          <div class="item-con">
            <!-- 循环项 -->
            <div class="l-item"  v-for="(item, index) in this.dataList"
          :key="index"
          @click="goDays(item.methods, item.id)">
              <img :src="item.img" alt="" />
              <p>{{ item.title }}</p>
            </div>
          
          </div>
        </div>
        <!-- 右侧 -->
        <div class="r">
          <img src="/imgs/i2.jpg" alt="" />
          <!-- 循环项容器 -->
          <div class="item-con">
            <!-- 循环项 -->
            <div class="l-item" v-for="(item, index) in this.dataList2"
          :key="index"
          @click="goDays(item.methods, item.id)">
              <img :src="item.img" alt="" />
              <p>{{ item.title }} <span>阅读{{ item.pay }}</span></p>
            </div>
          </div>
        </div>
      </div>

      <!-- banner -->
      <div class="banner">
        <img src="/imgs/banner.jpg" alt="" />
      </div>

      <!-- 分页器 -->
      <navigation
        :pages="pages"
        :current.sync="pageNo"
        @navpage="msgListView"
        style="text-align: center; display: none"
      ></navigation>
      <!-- modal 弹框 -->
      <modal
        title="图片"
        btnType="1"
        modalType="middle"
        :showModal="showModal"
        @submit="showModal = false"
        @cancel="showModal = false"
      >
        <template v-slot:body>
          <img :src="imgUrl" />
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import Modal from "../components/Modal.vue";
import NavHeader from "./../components/NavHeader";
import navigation from "./../components/navigation";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "index",
  data() {
    return {
      state: JSON.parse(window.localStorage.getItem("state")),
      href: "/",
      // 根数据
      dataList: [],
      dataList2: [],
      dataList3: [],
      // 总页数
      pages: 7,
      // 当前页
      pageNo: 1,
      // 点击页数
      curPage: 1,
      // 根据页数获取数据
      my_data: [],
      my_data2: [],
      my_data3: [],
      // 是否显示弹框
      showModal: false,

      imgUrl: "",
      // 轮播配置
      swiperOption: {
        autoplay: true,
        loop: true,
        effect: "fade",
        cubeEffect: {
          shadowOffset: 100,
          shadowScale: 0.6,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      // 轮播数组
      swiperList: [
        {
          id: "1",
          img: "/imgs/s1.jpg",
        },
        {
          id: "2",
          img: "/imgs/s2.jpg",
        },
      ],
    };
  },
  components: {
    NavHeader,
    navigation,
    Modal,
    Swiper,
    SwiperSlide,
  },
  mounted() {
    this.init();
    document.title = "杭州思阅科技有限公司";
  },
  methods: {
    // 分页器事件 //根据当前页获取数据
    msgListView(curPage) {
      this.curPage = curPage;
      console.log(this.curPage);
      this.init();
    },

    init() {
      // 渲染数据
      this.axios.get("/mock/a1.json").then((res) => {
        console.log(res);
        var data = res.data;
        // 根数据
        this.dataList = data;
      });

      this.axios.get("/mock/a2.json").then((res) => {
        var data = res.data;
        this.dataList2 = data;
      });
     
    },
    // 跳转 逻辑 封装
    goDays(methodsWords, type) {
      this[methodsWords](type);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + 1,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay(id) {
      // if (this.state == 0) {
      //   this.$router.push("/pay");
      //   return;
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("点击登录去购买~");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }

      this.$router.push({
        path: "/sound2/:" + 1,
        query: { type: id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
@import url("http://www.jq22.com/jquery/bootstrap-3.3.4.css");

.index {
  // swiper 轮播 区块
  .swiper {
    width: 100%;
    height: 500px;
    margin-bottom: 30px;
  }

  // 关于我们区块
  .us-con {
    width: 100%;
    height: 400px;
    // background-color: pink;
    margin-top: 15%;
    border-bottom: 6px solid $colorZ;
    .w {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      box-sizing: border-box;
      // 左侧
      .us-l {
        width: 60%;
        height: 100%;
        h3 {
          font-size: 38px;
          color: #888;
        }
        h2 {
          font-size: 34px;
          margin-top: 10px;
        }

        p {
          font-size: 12px;
          color: #888;
          line-height: 50px;
          margin-top: 15px;
        }

        .us-btn {
          width: 100px;
          height: 40px;
          background-color: $colorZ;
          color: #fff;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
          transition: all 0.4s;
          &:hover {
            border-radius: 20px;
          }
        }
      }
      // 右侧
      .us-r {
        width: 40%;
        height: 100%;
        margin-left: 30px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .w {
    // 动漫容器
    .con {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 60px 0;
      // 左侧
      .l {
        width: 49%;
        height: 100%;
        img {
          width: 100%;
          height: 200px;
          background-color: #000;
        }
        // 循环项
        .item-con {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          .l-item {
            width: 250px;
            height: 300px;
            margin: 15px 0;
            cursor: pointer;

            img {
              width: 100%;
              height: 111%;
              background: #fff;
              transition: all 0.4s;
              &:hover {
                transform: translateY(-6px);
              }
            }
            p {
              width: 100%;
              text-align: center;
              height: 20px;
              font-size: 12px;
              margin: 0;
              padding: 0;
              span {
                font-weight: bold;
                color: #c60023;
                margin-left: 5px;
                font-size: 14px;
              }
            }
          }
        }
      }
      // 右侧
      .r {
        width: 49%;
        height: 100%;
        img {
          width: 100%;
          height: 200px;
          background-color: #000;
        }

        // 循环项
        .item-con {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          .l-item {
            width: 250px;
            height: 300px;
            margin: 15px 0;
            cursor: pointer;

            img {
              width: 100%;
              height: 111%;
              background: #fff;
              transition: all 0.4s;
              &:hover {
                transform: translateY(-6px);
              }
            }
            p {
              width: 100%;
              text-align: center;
              height: 20px;
              font-size: 12px;
              margin: 0;
              padding: 0;
              span {
                font-weight: bold;
                color: #c60023;
                margin-left: 5px;
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    // banner
    .banner {
      width: 100%;
      height: 300px;
      background-color: pink;
      margin: 30px 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
