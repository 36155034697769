<template>
  <div class="nav-footer">
    <div class="w">
      <!-- 左侧 -->
      <div class="l">
        <h2>网站服务热线</h2>
        <h4>15868899529</h4>

        <p>杭州思阅科技有限公司</p>
        <p>邮箱：839387695@qq.com -- 域名：qinronread.com</p>
        <p>
          地址：浙江省杭州市拱墅区银泰城6幢1702室 --
          <a href="https://beian.miit.gov.cn/#/Integrated/index"
            >ICP备案编号：湘ICP备18009503号-1</a
          >
        </p>
      </div>
      <!-- 右侧 -->
      <div class="r">
        <p>杭州思阅科技有限公司成立于2022年03月29日，注册地位于浙江省杭州市拱墅区银泰城6幢1702室，经营范围包括：一般项目：组织文化艺术交流活动；技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；信息技术咨询服务；计算机系统服务；信息系统集成服务；广告制作；广告设计、代理；版权代理；广告发布；项目策划与公关服务；摄像及视频制作服务(除依法须经批准的项目外，凭营业执照依法自主开展经营活动)等...</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.nav-footer {
  width: 100%;
  height: 230px;
  background: #7a7979;
  .w {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // 左侧
    .l {
      h2 {
        font-size: 14px;
        font-family: "Microsoft YaHei";
        color: #fff;
      }
      h4 {
        font-size: 28px;
        color: #fff;
        font-weight: bold;
        margin: 10px 0 20px;
      }
      p {
        font-size: 12px;
        color: #fafafa;
        margin: 5px 0;
        a {
          color: #fff;
          text-decoration: none;
        }
      }
    }
    // 右侧
    .r {
      width: 50%;
      height: 100%;
      p {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        line-height: 30px;
        color: #fff;
        font-size: 12px;
      }
    }
  }
}
</style>
