<template>
  <div class="nav-header">
    <!-- 上层 -->
    <div class="nav-top">
      <!-- 顶宽布局 -->
      <div class="w">
        <!-- 左侧登录注册 -->
        <div class="n-login">
          <a href="javascript:;" @click="goLogin()" v-show="this.LoggedShow">登录/注册</a>
          <!-- <a href="javascript:;" @click="goLogin()" v-show="this.LoggedShow">注册</a> -->
          <!-- <span>|</span> -->
          <!-- <a href="javascript:;" @click="goRegister()" v-show="this.LoggedShow">注册</a> -->
          <a
            href="javascript:;"
            v-show="!this.LoggedShow"
            v-text="this.nv_user.Username"
          ></a>

          <a href="javascript:;" @click="tolg()" v-show="!this.LoggedShow">退出登录</a>
        </div>
        <!-- 中间logo -->
        <div class="n-logo">
          <img src="/imgs/logo.png" alt="" />
          <p>杭州思阅科技有限公司</p>
        </div>
        <!-- 右侧联系方式 -->
        <div class="n-info">
          <p><i class="iconfont"></i> 联系方式：15868899529</p>
        </div>
      </div>
    </div>
    <!-- 下层 -->
    <div class="nav-list">
      <!-- 顶宽布局 -->
      <div class="w">
        <a href="/">网站首页</a>
        <a href="/#/info">关于我们</a>
        <a href="/#/xy">用户协议</a>
        <a href="/#/xy2">未成年人限制条款</a>
        <a href="/#/xy3">交易纠纷处理</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "nav-header",
  props: {
    href: String,
  },
  data() {
    return {
      // 按钮显示/隐藏
      LoggedShow: true,
      // 获取到 user 用户信息
      nv_user: JSON.parse(window.localStorage.getItem("user")),
      // 存储状态
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.logged();
  },
  methods: {
    // 登录时隐藏逻辑
    logged() {
      if (this.state == "0") {
        this.LoggedShow = false;
      }
    },
    // 登录跳转
    goLogin() {
      this.$router.push("/login");
    },
    // 注册跳转
    goRegister() {
      this.$router.push("/register");
    },
    // 退出登录
    tolg() {
      window.localStorage.setItem("state", "10");
      this.$router.push("/index");
      this.LoggedShow = true;
      location.reload();
      // this.$forceUpdate()
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.nav-header {
  // 上层
  .nav-top {
    width: 100%;
    height: 130px;
    .w {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      // 左侧 登录注册
      .n-login {
        width: 25%;
        height: 100%;
        // background: red;
        display: flex;
        align-items: center;
        a {
          color: #333;
          font-size: 14px;
          margin-right: 15px;
          background: #ebebeb;
          width: 100px;
          height: 38px;
          display: block;
          line-height: 38px;
          text-align: center;
          text-decoration: none;
          transition: all 0.3s;
          border-radius: 2px;
          &:hover {
            background-color: $colorZ;
            color: #fff;
          }
        }
      }
      // 中间 logo
      .n-logo {
        flex: 1;
        height: 100%;
        text-align: center;
        img {
          width: 80px;
          height: 80px;
        }
        p {
          font-size: 14px;
          color: #000;
          // font-weight: bold;
          margin-top: 10px;
        }
      }
      // 右侧 联系方式
      .n-info {
        width: 25%;
        height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        p {
          font-size: 14px;
          color: #888;
          width: 100%;
          cursor: pointer;
        }
      }
    }
  }
  // 下层
  .nav-list {
    width: 100%;
    height: 50px;
    background-color: #9d2933;
    .w {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      a {
        color: #fff;
        text-decoration: none;
        width: 170px;
        height: 50px;
        // background-color: rgba(0,0,0,.2);
        line-height: 50px;
        text-align: center;
        transition: all .3s;
        font-size: 14px;
        &:hover {
          background-color: rgba(0,0,0,.2);
        }
      }
    }
  }
}
</style>
